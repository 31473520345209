<template>
    <div>
        Password reset completed
    </div>
</template>

<script>

// Visit this page using Vue.js hash mode URL. Ex. http://localhost:8083/#/resetdone
export default {
    name: 'Resetdone',
    data() {
        return {}
    },
    mounted() {
        window.location.assign('/');
    }
};

</script>